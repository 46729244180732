<template>
<b-card :title="$t('users')">
  <b-row>
    <b-col md="4" class="my-1">

    </b-col>
    <b-col md="8" class="my-1">
      <div class="text-right">
        <router-link :to="{ name: 'users.create' }" class="btn btn-sm btn-primary">{{ $t('add') }}</router-link>
      </div>
    </b-col>
  </b-row>
  <b-table
    responsive
    striped
    hover
    show-empty
    sort-icon-left
    :filter="filter"
    :items="items"
    :fields="fields"
    :current-page="currentPage"
    @sort-changed="handleSortChange"
    :per-page="0" @filtered="onFiltered">
    <template slot="top-row" slot-scope="{ fields }">
      <td v-for="field in fields" :key="field.key">
        <b-form-input v-if="field.typesort === 'text'" size="sm" :placeholder="field.label" @change="searchByText(field.key, $event)"></b-form-input>
      </td>
    </template>
    <template v-slot:cell(actions)="row">
      <b-button-group>
        <b-button v-if="!row.item.verified" size="sm" variant="success" @click="verified(row.item.id)">
          <fa icon="check" />
        </b-button>
        <b-button size="sm" variant="primary" @click="info(row.item, row.index, $event.target)">
          <fa icon="eye" />
        </b-button>
        <router-link :to="{ name: 'users.edit', params: { 'id': row.item.id } }" class="btn btn-sm btn-warning"><fa icon="pencil-alt" /></router-link>
        <b-button size="sm" variant="danger" @click="removeItem(row.item.id)">
          <fa icon="trash" />
        </b-button>
      </b-button-group>
    </template>
  </b-table>
  <!-- Pagination -->
  <b-pagination size="sm" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"/>
  <!-- Modal info -->
  <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
    <div v-html="infoModal.content"></div>
  </b-modal>
</b-card>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('users') }
    },
    data: () => ({
      items: [],
      fields: [
        {
          key: 'role',
          label: 'Роль',
          class: 'text-left text-danger'
        },
        {
          key: 'first_name',
          label: 'Ім"я',
          typesort: 'text'
        },
        {
          key: 'last_name',
          label: 'Фамілія',
          typesort: 'text'
        },
        {
          key: 'email',
          label: 'Email',
          typesort: 'text'
        },
        {
          key: 'phone',
          label: 'Телефон',
          sortable: true,
          typesort: 'text'
        },
        {
          key: 'created_at',
          label: 'Додано'
        },
        {
          key: 'actions',
          label: 'Дії'
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      editModal: {
        id: 'edit-modal',
        title: ''
      },
      urlParams: {}
    }),
    mounted () {
        this.fetchData().catch(error => {
            console.log(error)
        })
    },
    methods: {
      async fetchData () {
        const params = this.buildQuery() !== '' ? '&' + this.buildQuery() : ''
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const { data } = await axios.get(apiUrl + 'users?page=' + this.currentPage + params)
        this.items = data.data
        this.currentPage = data.meta.pagination.current_page
        this.perPage = data.meta.pagination.per_page
        this.totalItems = data.meta.pagination.total
      },
      async verified (id) {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const result = confirm(this.$t('want_to_vefify'))
        if (result) {
          this.$root.$refs.loading.start()
          await axios.post(apiUrl + 'users/verify/' + id).then(resp => {
            if (resp.data.status) {
              Swal.fire({
                icon: 'success',
                title: this.$t('success_user_verify'),
                text: resp.data.msg,
                reverseButtons: true,
                confirmButtonText: this.$t('ok'),
                cancelButtonText: this.$t('cancel')
              })
              this.fetchData()
              this.$root.$refs.loading.finish()
            }
          })
        }
      },
      async removeItem (id) {
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const result = confirm(this.$t('want_to_delete'))
        if (result) {
          await axios.delete(apiUrl + 'users/' + id)
          this.fetchData()
          this.$root.$refs.loading.finish()
        }
      },
      searchByText (key, val) {
        this.urlParams[key] = val
        this.fetchData()
      },
      info (item, index, btn) {
        this.infoModal.title = 'Інформація про користувача'
        let html = '<ul>'
            html += '<li><strong>Імя:</strong> ' + item.first_name + '</li>'
            html += '<li><strong>Фамілія:</strong> ' + item.last_name + '</li>'
            html += '<li><strong>Побатькові:</strong> ' + item.surname + '</li>'
            html += '<li><strong>Телефон:</strong> ' + item.phone + '</li>'
            html += '<li><strong>Е-мейл:</strong> ' + item.email + '</li>'
            if (item.card_number) {
              html += '<li><strong>Номер карти:</strong> ' + item.card_number + '</li>'
            }
            if (item.role) {
              html += '<li><strong>Роль:</strong> <span class="badge badge-primary">' + item.role + '</span></li>'
            }
            html += '<li><strong>Email підтверджено:</strong> ' + item.email_verified + '</li>'
            html += '<li><strong>Зареєстрований:</strong> ' + item.created_at + '</li>'
            html += '</ul>'
        this.infoModal.content = html
        this.$root.$emit('bv::show::modal', this.infoModal.id, btn)
      },
      resetInfoModal () {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered (row, filter) {
        console.log(filter)
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      handleSortChange (context) {
        const sortBy = context.sortDesc ? 'DESC' : 'ASC'
        this.urlParams[context.sortBy] = sortBy
        this.fetchData()
      },
      buildQuery () {
        return Object.entries(this.urlParams).map(pair => pair.map(encodeURIComponent).join('=')).join('&')
      }
    },
    watch: {
        currentPage: {
            handler: function (value) {
                this.fetchData().catch(error => {
                    console.log(error)
                })
            }
        }
    }
}
</script>
